<template>
  <v-card class="pa-5">
    <v-container>
      <v-form ref="form" @submit.prevent="submitCategory" v-model="isFormValid">
        <v-text-field label="Category Name" v-model="categoryName" :rules="nameRules" required></v-text-field>

        <v-select
          v-model="selectedColor"
          :items="colors"
          label="Color"
          item-text="name"
          item-value="value"
          :rules="[v => !!v || 'Color is required']"
          required
        ></v-select>

        <v-file-input
          v-model="image"
          label="Upload Image"
          accept="image/*"
          :rules="[v => !!v || 'Image is required']"
          required
        ></v-file-input>

        <v-btn :disabled="!isFormValid" type="submit" color="primary" class="ma-2">
          <v-icon left>mdi-plus</v-icon>Add Category
        </v-btn>
      </v-form>

      <v-data-table
        :headers="headers"
        :items="categories"
        :items-per-page="5"
        class="elevation-1"
        :loading="isLoading"
        item-class="data-table-item"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Categories</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="fetchCategories" icon>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item.color="{ item }">
          <v-chip :color="item.color" class="white--text">{{ item.color }}</v-chip>
        </template>

        <template v-slot:item.image="{ item }">
          <v-img :src="item.fileUrl" max-width="100" max-height="100" class="mx-auto"></v-img>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editCategory(item)">mdi-pencil</v-icon>
          <v-icon small class="mr-2" @click="deleteCategory(item.id)">mdi-delete</v-icon>
        </template>
      </v-data-table>

      <v-dialog v-model="editDialog" max-width="500px">
        <v-card>
          <v-card-title>Edit Category</v-card-title>
          <v-card-text>
            <v-form ref="editForm" @submit.prevent="updateCategory" v-model="isEditFormValid">
              <v-text-field label="Category Name" v-model="editCategoryData.name" :rules="nameRules" required></v-text-field>

              <v-select
                v-model="editCategoryData.color"
                :items="colors"
                label="Color"
                item-text="name"
                item-value="value"
                :rules="[v => !!v || 'Color is required']"
                required
              ></v-select>

              <v-file-input
                v-model="editCategoryData.image"
                label="Upload Image"
                accept="image/*"
              ></v-file-input>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editDialog = false">
              <v-icon left>mdi-cancel</v-icon>Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="updateCategory" :disabled="!isEditFormValid">
              <v-icon left>mdi-content-save</v-icon>Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      categoryName: '',
      selectedColor: '',
      image: null,
      categories: [],
      colors: [
        { name: 'Red', value: 'red' },
        { name: 'Green', value: 'green' },
        { name: 'Blue', value: 'blue' },
      ],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Color', value: 'color' },
        { text: 'Image', value: 'fileUrl', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editDialog: false,
      editCategoryData: {
        id: null,
        name: '',
        color: '',
        image: null,
      },
      isLoading: false,
      isFormValid: false,
      isEditFormValid: false,
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length >= 3 || 'Name must be at least 3 characters',
      ],
    };
  },
  methods: {
    async submitCategory() {
      if (!this.$refs.form.validate()) return;

      try {
        const formData = new FormData();
        formData.append('file', this.image);

        const uploadResponse = await axios.post('https://qorani-qorani-test.gbapgy.easypanel.host/library/file/upload', formData);
        const { fileId, fileUrl } = uploadResponse.data;

        const categoryData = {
          name: this.categoryName,
          color: this.selectedColor,
          fileId: fileId,
          fileUrl: fileUrl,
        };

        this.isLoading = true;
        await axios.post('https://qorani-qorani-test.gbapgy.easypanel.host/library/category/add', categoryData);
        this.fetchCategories();
        this.$refs.form.reset();
      } catch (error) {
        console.error('Error adding category:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCategories() {
      this.isLoading = true;
      try {
        const response = await axios.get('https://qorani-qorani-test.gbapgy.easypanel.host/library/category/getall');
        this.categories = response.data;
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        this.isLoading = false;
      }
    },
    editCategory(category) {
      this.editCategoryData = { ...category, image: null };
      this.editDialog = true;
    },
    async updateCategory() {
      if (!this.$refs.editForm.validate()) return;

      try {
        let { fileId, fileUrl } = this.editCategoryData;

        if (this.editCategoryData.image) {
          const formData = new FormData();
          formData.append('file', this.editCategoryData.image);

          const uploadResponse = await axios.post('https://qorani-qorani-test.gbapgy.easypanel.host/library/file/upload', formData);
          fileId = uploadResponse.data.fileId;
          fileUrl = uploadResponse.data.fileUrl;
        }

        const categoryData = {
          name: this.editCategoryData.name,
          color: this.editCategoryData.color,
          fileId: fileId,
          fileUrl: fileUrl,
        };

        this.isLoading = true;
        await axios.post(`https://qorani-qorani-test.gbapgy.easypanel.host/library/category/edit/${this.editCategoryData.id}`, categoryData);
        this.fetchCategories();
        this.editDialog = false;
      } catch (error) {
        console.error('Error updating category:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteCategory(id) {
      try {
        this.isLoading = true;
        await axios.delete(`https://qorani-qorani-test.gbapgy.easypanel.host/library/category/delete/${id}`);
        this.fetchCategories();
      } catch (error) {
        console.error('Error deleting category:', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.fetchCategories();
  },
};
</script>

<style scoped>
.data-table-item {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.data-table-item:hover {
  background-color: #f5f5f5;
}
</style>
